export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const FETCH_START = "FETCH_START";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";

export const SET_CHAT_USERS = "SET_CHAT_USERS";
export const SET_CONTACT_USERS = "SET_CONTACT_USERS";
export const SET_ROOMS_USER = "SET_ROOMS_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const SET_SELECTED_USER_ROOM = "SET_SELECTED_USER_ROOM";
export const SET_CONVERSATION_DATA = "SET_CONVERSATION_DATA";
export const SET_ROOM_CONVERSATION_DATA = "SET_ROOM_CONVERSATION_DATA";
export const SEND_NEW_MEDIA_MESSAGE_ROOM = "SEND_NEW_MEDIA_MESSAGE_ROOM";
export const SEND_NEW_CHAT_MESSAGE_ROOM = "SEND_NEW_CHAT_MESSAGE_ROOM";
export const RECEIVE_NEW_CHAT_MESSAGE_ROOM = "RECEIVE_NEW_CHAT_MESSAGE_ROOM";
export const RECEIVE_NEW_CHAT_MESSAGE = "RECEIVE_NEW_CHAT_MESSAGE";

export const SEND_NEW_CHAT_MESSAGE = "SEND_NEW_CHAT_MESSAGE";
export const SEND_NEW_MEDIA_MESSAGE = "SEND_NEW_MEDIA_MESSAGE";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";

export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
export const UPDATE_LOAD_USER = "UPDATE_LOAD_USER";

export const ADD_ROOM_TO_LIST = "ADD_ROOM_TO_LIST";
export const LEAVE_ROOM = "LEAVE_ROOM";
export const DELETE_CONTACT = "DELETE_CONTACT";

export const RECEIVE_NEW_MEDIA_MESSAGE = "RECEIVE_NEW_MEDIA_MESSAGE";
export const RECEIVE_NEW_ROOM_MEDIA_MESSAGE = "RECEIVE_NEW_ROOM_MEDIA_MESSAGE";

export const BAN_USER_FROM_ROOM = "BAN_USER_FROM_ROOM";
